import React, { FC, useState } from "react"
import classnames from "classnames"
import "gatsby-theme-gaviscon/src/components/Quiz/components/QuizSlide/QuizSlide.scss"
import Button from "components/common/Button"
import IconCustom from "components/common/IconCustom"
import DangerouslySetInnerHtml from "components/common/DangerouslySetInnerHtml"
import { parseBoolean } from "utils/parseHelpers"
import QuizOption from "gatsby-theme-gaviscon/src/components/Quiz/components/QuizOption/QuizOption"

const QuizSlide: FC<QuizTypes.IQuizSlide> = ({
  title,
  url,
  id,
  prevSlide,
  options,
  handlePrevSlide,
  handleNextSlide,
  handleOptionSelected,
  currentSlide,
  prevButton,
  nextButton,
  ariaResult,
  resultButton,
  ariaNext,
  ariaPrev,
  disclaimer,
  listMode,
  handleSeeRsult,
}) => {
  const [selected, setSelected] = useState(-1)
  const [isLastSlide, setIsLastSlide] = useState(false)
  const slideCSSclassNames = classnames("quiz-slide", {
    "quiz-slide--active": url === currentSlide?.content.url,
    "quiz-slide--list-mode": parseBoolean(listMode),
  })

  const onPrevSlide = () => {
    setSelected(-1)
    handlePrevSlide(prevSlide?.[0]?.url)
  }

  const onNextSlide = () => {
    const { nextSlide } = options[selected]?.properties
    handleNextSlide(nextSlide?.[0]?.url)
  }

  const handleChange = e => {
    const selectedOptionInd = Number(e.currentTarget.value)
    options[selectedOptionInd].properties.nextSlide?.length
      ? setIsLastSlide(false)
      : setIsLastSlide(true)

    handleOptionSelected(
      options[selectedOptionInd]?.properties.tags,
      currentSlide.content.properties.step - 1
    )
    setSelected(selectedOptionInd)
  }

  return (
    <div className={slideCSSclassNames} data-testid="quiz-slide">
      <h2 className="quiz-slide__title">{title}</h2>
      {options?.length ? (
        <div className="quiz-slide__options">
          {options.map(({ properties }, idx) => (
            <QuizOption
              key={properties.title}
              {...{ ...properties, id, handleChange, selected, idx, listMode }}
            />
          ))}
        </div>
      ) : null}
      <div className="quiz-slide__disclaimer">
        {disclaimer ? (
          <DangerouslySetInnerHtml
            className="quiz-slide__disclaimer-text"
            html={disclaimer}
          />
        ) : null}
      </div>
      <div className="quiz-slide__nav">
        {prevSlide?.length ? (
          <Button
            classes="quiz-slide__nav-prev"
            ariaLabel={ariaPrev}
            variant="pink-outline"
            clickHandler={onPrevSlide}
          >
            <IconCustom icon="chevron-left" />
            {prevButton}
          </Button>
        ) : null}
        {selected >= 0 ? (
          isLastSlide ? (
            <Button
              classes="quiz-slide__nav-next"
              ariaLabel={ariaResult}
              variant="pink"
              clickHandler={handleSeeRsult}
            >
              {resultButton}
              <IconCustom icon="chevron-right" />
            </Button>
          ) : (
            <Button
              classes="quiz-slide__nav-next"
              ariaLabel={ariaNext}
              variant="pink"
              clickHandler={onNextSlide}
            >
              {nextButton}
              <IconCustom icon="chevron-right" />
            </Button>
          )
        ) : null}
      </div>
    </div>
  )
}

export default QuizSlide
